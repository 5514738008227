import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

import Layout from '../components/layout'
import Seo from '../components/seo'

const StyledList = styled.ul`
  columns: 4;

  @media (max-width:768px) {
    columns: 3;
  }

  @media (max-width:414px) {
    columns: 1;
  }
`

const HomePage = () => (
  <Layout>
    <Seo />

    <div style={{ display: "grid", background: "#000", height: "300px" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          maxHeight: 400,
        }}
        layout="fullWidth"
        src="../images/header/storage-assurance-hero-bg.jpg"
        alt="Storage Assurance"
        placeholder="tracedSVG"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <h2 className="text-white text-5xl md:text-6xl font-extrabold text-center uppercase p-6">
          Before You File An Incident Report
        </h2>
      </div>
    </div>

    <div className="max-w-screen-xl my-16 mx-auto px-4">
      <h3 className="text-gray-800 text-3xl text-center leading-normal">Disasters can happen. Luckily, you've prepared and opted in for <span className="font-semibold text-red-700">Storage Assurance</span>. If your unit contents were damaged by the following you can file an incident report through your protection plan.</h3>
    </div>

    <div className="max-w-screen-lg my-16 mx-auto px-4">
      <StyledList className="list-disc list-inside ml-12 my-8">
        <li className="text-xl font-medium tracking-wider mb-2">earthquake</li>
        <li className="text-xl font-medium tracking-wider mb-2">explosion</li>
        <li className="text-xl font-medium tracking-wider mb-2">water damage</li>
        <li className="text-xl font-medium tracking-wider mb-2">building collapse</li>
        <li className="text-xl font-medium tracking-wider mb-2">vandalism</li>
        <li className="text-xl italic font-medium tracking-wider mb-2">burglary</li>
        <li className="text-xl font-medium tracking-wider mb-2">riot</li>
      </StyledList>
    </div>

    <div className="max-w-screen-lg mt-16 mx-auto px-4">
      <h3 className="text-gray-800 text-3xl text-center leading-normal uppercase font-bold">Before You File An Incident Report, Please Ensure You Have Done The Following:</h3>

      <p className="max-w-xl italic text-center mt-6 mx-auto leading-relaxed">*Contact the police if the loss is due to burglary, vandalism, malicious mischief or vehicle collision. A police report must be obtained and provided to file an incident report for these instances.</p>
    </div>

    <div className="max-w-2xl mt-8 mb-16 mx-auto px-4">
      <ol className="list-inside list-decimal space-y-6 md:ml-12">
        <li className="text-xl leading-normal">Notify facility management immediately when a loss is discovered.</li>
        <li className="text-xl leading-normal">Secure remaining property to prevent further loss.</li>
        <li className="text-xl leading-normal">Do not discard damaged property until a incident report adjuster has contacted you and advised that these may be discarded. If this is not possible, take a photo or video of the item(s) before discarding them.</li>
      </ol>
    </div>

    <div className="max-w-lg mt-16 mb-24 mx-auto px-4">
      <a href="https://www.tfaforms.com/4946996" target="_blank" rel="noopener noreferrer">
        <button className="w-full bg-red-700 hover:bg-red-800 text-white text-xl md:text-2xl font-semibold uppercase tracking-wider py-3 px-10 rounded-md shadow-md">File An Incident Report</button>
      </a>
    </div>
  </Layout>
)

export default HomePage